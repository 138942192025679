import {add_error, clear_error} from '../shared/form_errors.js'
import moment from "moment";



document.addEventListener('ajax:complete', event => {
    const request = event.detail[0];
    const msg = request.getResponseHeader("X-Message");
    let alertType = 'alert-success';
    if (request.getResponseHeader("X-Message-Type").includes("error")) {
        alertType = 'alert-danger';
    }
    if (!request.getResponseHeader("X-Message-Type").startsWith("keep")) {
        if (msg) {
            document.getElementById("flash_hook").outerHTML = `<div id='flash_hook'> <p>&nbsp;</p> <div class='row'> <div class='span10 offset1'> <div class='alert ${alertType}'> <button type='button' class='close' data-dismiss='alert'>&times;</button>${msg}</div> </div> </div> </div>`;
        } else {
            document.getElementById("flash_hook").outerHTML = "<div id='flash_hook'></div>";
        }
    }
});

//character counter

document.querySelectorAll('.count-text').forEach(element => element.addEventListener('keyup', (event) => {
    const group = event.target.closest('.input-group');
    const counter = group.querySelector('.counter');
    const max_length = parseInt(counter.dataset.max) - counter.textContent.length;
    const cur_count = max_length - event.target.value.length;
    if (cur_count <= 0) {
        counter.style.color = 'red';
    } else {
        counter.style.color = 'lightgray';
    }
    counter.textContent = 'Characters left: ' + String(cur_count);
}));


document.querySelectorAll('[id$=color_swatch]').forEach(element => {
    element.addEventListener('change', (event) => {
        setSwatchColor(event.target);
    });
});

function setSwatchColor(element) {
    const color = element.value;
    const group = element.closest('.input-group');
    const colorSwatch = group.querySelector('.color-swatch');
    colorSwatch.style.backgroundColor = color;
}

const dateInputs = document.querySelectorAll('input[type="date"]');
dateInputs.forEach(input => {
    input.addEventListener('blur', () => {
        if (input.value !== '') {
            moment(input.value, "YYYY-MM-DD", true).isValid() ? clear_error(input) : add_error(input, 'Invalid Date');
        }
    });
});







